/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../webfonts/poppins/poppins-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Poppins Regular'), local('Poppins-Regular'),
       url('../webfonts/poppins/poppins-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../webfonts/poppins/poppins-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../webfonts/poppins/poppins-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../webfonts/poppins/poppins-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../webfonts/poppins/poppins-v12-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../webfonts/poppins/poppins-v12-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Poppins Medium'), local('Poppins-Medium'),
       url('../webfonts/poppins/poppins-v12-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../webfonts/poppins/poppins-v12-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../webfonts/poppins/poppins-v12-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../webfonts/poppins/poppins-v12-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../webfonts/poppins/poppins-v12-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}




/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3c04bb");

@font-face {
  font-family: 'Hawkes-BoldRegular';
  src: url('../webfonts/hawkes/font.woff2') format('woff2'), url('../webfonts/hawkes/font.woff') format('woff');
}
