@import 'particles/fonts';
@import 'settings';
/* =============================================================================
// blocks.PHP
// -----------------------------------------------------------------------------
// Registriere Blöcke
// =============================================================================


// =============================================================================
// TABLE OF CONTENTS
// -----------------------------------------------------------------------------
//   01. Seitenbaum links
//   02. Mittlerer Bereich
//   03. Sidebar rechts
//   04. Dashboard
//   04. weitere / sonstige Styles
*/

/*???*/
.acf-fc-layout-handle {
  background-color: $primary-color;
  border-left: 4px solid $dark-gray !important;
}
/* =============================================================================
// Seitenbaum links
// -----------------------------------------------------------------------------
*/
// Farbe Offene Menus
#adminmenu .wp-has-current-submenu .wp-submenu .wp-submenu-head,
#adminmenu .wp-menu-arrow,
#adminmenu .wp-menu-arrow div,
#adminmenu li.current a.menu-top,
#adminmenu li.wp-has-current-submenu a.wp-has-current-submenu,
.folded #adminmenu li.current.menu-top,
.folded #adminmenu li.wp-has-current-submenu {
  background: $primary-color !important;
}
// Hover Farbe Menupunkte
#adminmenu .wp-has-current-submenu .wp-submenu,
#adminmenu .wp-has-current-submenu .wp-submenu.sub-open,
#adminmenu .wp-has-current-submenu.opensub .wp-submenu,
#adminmenu a.wp-has-current-submenu:focus+.wp-submenu,
#adminmenu li > a.menu-top:focus,
#adminmenu li.menu-top:hover,
#adminmenu li.opensub > a.menu-top,
.no-js li.wp-has-current-submenu:hover .wp-submenu {
  color: $dark-gray !important;
  background: $primary-color;
}
// Hover Farbe von rechts rausragenden Menus (zB Desgin)
#adminmenu .wp-submenu a:focus,
#adminmenu .wp-submenu a:hover,
#adminmenu a:hover,
#adminmenu li.menu-top > a:focus {
  color: $dark-gray !important;
  background: $primary-color;
}
// Hoverfarbe des Icons vor dem gehoverten Menupunkt
#adminmenu li a:focus div.wp-menu-image::before,
#adminmenu li.opensub div.wp-menu-image::before,
#adminmenu li:hover div.wp-menu-image::before {
  color: $dark-gray !important;
}
// Hintergrundfarbe des gesamten Seitenbaums
#adminmenu,
#adminmenu .wp-submenu,
#adminmenuback,
#adminmenuwrap {
  background: $dark-gray;
}


// Logo in der linken Menuleiste
#adminmenuback{
&:after {
  content: "";
  position: absolute;
  top: -160px;
  left: -12px;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: -1;
  background: url("../img/standard/fallback-logo.svg");
  background: url("../img/projekt/logo.svg");
  background-position: right bottom;
  background-size: 140px auto;
  background-repeat: no-repeat;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
}

// Pfeil im Menupunkt beim hovern
#adminmenu li.wp-has-submenu.wp-not-current-submenu.opensub:hover::after {
  border-right-color: $dark-gray;
}
// Menu einklappen Schriftfarbe
#collapse-button {
  color: $primary-color;
  &:hover{
    color: darken($primary-color, 15%);
  }
}
/* =============================================================================
// Mittlerer Bereich
// -----------------------------------------------------------------------------
*/


/* Align-Wide und Align-Full Label über jedem Block */

/* NOCH EXPERIMENTELL  */
/*.components-dropdown-menu__menu{
  >.components-button{
      font-size: 0;
      &:first-child{
        &::after{
          content: "im Grid";
        }
      &:nth-child(2){
        &::after{
          content: "Full-Width";
        }
      }
      }
      &::after{
        display: block;
        color: $body-font-color;
        font-size: 13px;
        text-transform: uppercase;
      }
  }
}*/


.components-icon-button.components-dropdown-menu__menu-item.is-active.has-text{
  font-size: 0;

}
components-button components-icon-button components-dropdown-menu__menu-item has-text


/* Block Buttons (Eintrag hinzufügen)*/
.wp-core-ui .button-primary {
  background: $dark-gray;
  border-color: $primary-color;
  text-shadow: 0 -1px 1px $dark-gray, 1px 0 1px $dark-gray, 0 1px 1px $dark-gray, -1px 0 1px $dark-gray;
  box-shadow: 0 1px 0 $dark-gray;
  -webkit-animation: glowing 3000ms infinite;
 -moz-animation: glowing 3000ms infinite;
 -o-animation: glowing 3000ms infinite;
 animation: glowing 3000ms infinite;
}

.wp-core-ui .button-primary.focus,
.wp-core-ui .button-primary.hover,
.wp-core-ui .button-primary:focus,
.wp-core-ui .button-primary:hover {
  background: $primary-color;
  border-color: $primary-color;
  color: $white;
}

.wp-core-ui .button-primary.focus,
.wp-core-ui .button-primary:focus {
  box-shadow: 0 1px 0 $dark-gray, 0 0 2px 1px $primary-color;
}
/* Button innerhalb der Post Types / Posts */
.wrap .add-new-h2:hover,
.wrap .page-title-action {
  color: $primary-color;
  border-color: $primary-color;

  &:hover {
    background: $primary-color !important;
    border: 1px solid $primary-color !important;
    text-shadow: $primary-color 0 1px 0 !important;
    color: $dark-gray;
  }
}
// Breite der Blöcke im mittleren Bereich
.wp-block {
  max-width: 75rem;
}
/* linkfarbe in der Auflistung aller Seiten*/
.row-title {
  color: $primary-color;
}

// Farbe der Repeater Feld Tabellen
.acf-table > tbody > tr{
  background: $white;
}
.acf-repeater .acf-row-handle.order{
  background: none !important;
}

// Auf Entwurf umstellen
.components-button.is-tertiary{
  color: $primary-color;
}

// Vorschau Button
.components-button.is-default{
  background: $light-gray;
  border-color: $primary-color;
  color: $primary-color;
}

// Aktualisieren Button
.components-button.is-primary:disabled, .components-button.is-primary:disabled:active:enabled, .components-button.is-primary[aria-disabled="true"], .components-button.is-primary[aria-disabled="true"]:active:enabled, .components-button.is-primary[aria-disabled="true"]:enabled{
  border-color: darken($primary-color,5%);
  background: $primary-color;
}

.components-button.is-primary{
  background: $primary-color;
  color: $white !important;
  -webkit-animation: glowing 3000ms infinite;
 -moz-animation: glowing 3000ms infinite;
 -o-animation: glowing 3000ms infinite;
 animation: glowing 3000ms infinite;
}

// Animation für den Aktualisieren Button
@-webkit-keyframes glowing {
  0% { background-color: $primary-color; -webkit-box-shadow: 0 0 3px $primary-color; }
  50% { background-color: lighten($primary-color, 15%); -webkit-box-shadow: 0 0 10px lighten($primary-color, 15%); }
  100% { background-color: $primary-color; -webkit-box-shadow: 0 0 3px $primary-color; }
}

@-moz-keyframes glowing {
  0% { background-color: $primary-color; -moz-box-shadow: 0 0 3px $primary-color; }
  50% { background-color: lighten($primary-color, 15%); -moz-box-shadow: 0 0 10px lighten($primary-color, 15%); }
  100% { background-color: $primary-color; -moz-box-shadow: 0 0 3px $primary-color; }
}

@-o-keyframes glowing {
  0% { background-color: $primary-color; box-shadow: 0 0 3px $primary-color; }
  50% { background-color: lighten($primary-color, 15%); box-shadow: 0 0 10px lighten($primary-color, 15%); }
  100% { background-color: $primary-color; box-shadow: 0 0 3px $primary-color; }
}

@keyframes glowing {
  0% { background-color: $primary-color; box-shadow: 0 0 3px $primary-color; }
  50% { background-color: lighten($primary-color, 15%); box-shadow: 0 0 10px lighten($primary-color, 15%); }
  100% { background-color: $primary-color; box-shadow: 0 0 3px $primary-color; }
}

/* =============================================================================
// Dashboard
// -----------------------------------------------------------------------------
*/
/* Boxen im Dashboard */
.postbox {
  //border-radius: 8px;
  a{
    color: $primary-color;
  }
}
/* Hintergrundfläche Dashboard */
#wpwrap::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  z-index: -1;
  background: url("../img/standard/fallback-logo.svg");
  background: url("../img/projekt/logo.svg");
  background-position: right bottom;
  background-size: 500px auto;
  background-repeat: no-repeat;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}


// Linkfarbe im Dashboard
#wpcontent{
  #welcome-panel{
    a{
      color: $primary-color;
    }
    .button-primary{
      text-shadow: 0 0 0 rgba($white,0),0 0 0 rgba($white,0),0 0 0 rgba($white,0),0 0 0 rgba($white,0) !important;
        color: $white;

    }
  }
}

/* =============================================================================
// Sidebar rechts
// -----------------------------------------------------------------------------
*/
/* Autoren Box auf "Seiten" entfernen */
#authordiv,
#commentsdiv {
  //  display: none !important;
}
/* Seitenleiste Menu rechts (zB Box Veröffentlichen, Seitenattribute etc.) */
#poststuff .stuffbox > h3,
#poststuff h2,
#poststuff h3.hndle {
  background: $primary-color !important;
  color: $white;
}
// Sidebar Rechts breite
.edit-post-layout.is-sidebar-opened .edit-post-plugin-sidebar__sidebar-layout,
.edit-post-layout.is-sidebar-opened .edit-post-sidebar {
  //width: 30%;
  //max-width: 20rem;
}
/* =============================================================================
// TOP MENU
// -----------------------------------------------------------------------------
*/

// Topbar Hover Farbe der Links
#wpadminbar .ab-top-menu > li.hover > .ab-item, #wpadminbar.nojq .quicklinks .ab-top-menu > li > .ab-item:focus, #wpadminbar:not(.mobile) .ab-top-menu > li:hover > .ab-item, #wpadminbar:not(.mobile) .ab-top-menu > li > .ab-item:focus{
  color: $dark-gray;
}

// Topbar Hover Farbe des Home Icons
#wpadminbar .quicklinks .ab-sub-wrapper .menupop.hover > a, #wpadminbar .quicklinks .menupop ul li a:focus, #wpadminbar .quicklinks .menupop ul li a:focus strong, #wpadminbar .quicklinks .menupop ul li a:hover, #wpadminbar .quicklinks .menupop ul li a:hover strong, #wpadminbar .quicklinks .menupop.hover ul li a:focus, #wpadminbar .quicklinks .menupop.hover ul li a:hover, #wpadminbar .quicklinks .menupop.hover ul li div[tabindex]:focus, #wpadminbar .quicklinks .menupop.hover ul li div[tabindex]:hover, #wpadminbar li #adminbarsearch.adminbar-focused::before, #wpadminbar li .ab-item:focus .ab-icon::before, #wpadminbar li .ab-item:focus::before, #wpadminbar li a:focus .ab-icon::before, #wpadminbar li.hover .ab-icon::before, #wpadminbar li.hover .ab-item::before, #wpadminbar li:hover #adminbarsearch::before, #wpadminbar li:hover .ab-icon::before, #wpadminbar li:hover .ab-item::before, #wpadminbar.nojs .quicklinks .menupop:hover ul li a:focus, #wpadminbar.nojs .quicklinks .menupop:hover ul li a:hover{
  color: $dark-gray;
}

// Topbar Hover Farbe des Submenus
#wpadminbar .quicklinks .ab-sub-wrapper .menupop.hover > a, #wpadminbar .quicklinks .menupop ul li a:focus, #wpadminbar .quicklinks .menupop ul li a:focus strong, #wpadminbar .quicklinks .menupop ul li a:hover, #wpadminbar .quicklinks .menupop ul li a:hover strong, #wpadminbar .quicklinks .menupop.hover ul li a:focus, #wpadminbar .quicklinks .menupop.hover ul li a:hover, #wpadminbar .quicklinks .menupop.hover ul li div[tabindex]:focus, #wpadminbar .quicklinks .menupop.hover ul li div[tabindex]:hover, #wpadminbar li #adminbarsearch.adminbar-focused::before, #wpadminbar li .ab-item:focus .ab-icon::before, #wpadminbar li .ab-item:focus::before, #wpadminbar li a:focus .ab-icon::before, #wpadminbar li.hover .ab-icon::before, #wpadminbar li.hover .ab-item::before, #wpadminbar li:hover #adminbarsearch::before, #wpadminbar li:hover .ab-icon::before, #wpadminbar li:hover .ab-item::before, #wpadminbar.nojs .quicklinks .menupop:hover ul li a:focus, #wpadminbar.nojs .quicklinks .menupop:hover ul li a:hover{
  color: $dark-gray;

}



// Home Icon Hover in der Topbar (Viereck oben links)
#wpadminbar .ab-top-menu > li.hover > .ab-item,
#wpadminbar .menupop .ab-sub-wrapper,
#wpadminbar .shortlink-input,
#wpadminbar.nojq .quicklinks .ab-top-menu > li > .ab-item:focus,
#wpadminbar:not(.mobile) .ab-top-menu > li > .ab-item:focus,
#wpadminbar:not(.mobile) .ab-top-menu > li:hover > .ab-item {
  background: $primary-color;
}
// Topbar
#wpadminbar {
  background: $dark-gray;
}
/* TOPBAR LOGO oben links */
#wpadminbar #wp-admin-bar-wp-logo > .ab-item .ab-icon::before {
  z-index: 9999;
  background-size: 22px auto;
  background-image: url("../img/projekt/logo.svg") !important;
  background-position: 0 0;
  color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-position: center;
}


// Home Icon oben links Hover
#wpadminbar #wp-admin-bar-wp-logo.hover > .ab-item .ab-icon {
  background-position: 0 0;
}


/* =============================================================================
// weitere / sonstige Styles
// -----------------------------------------------------------------------------
*/

// Font Family im Backend
body.wp-admin{
  font-family: $body-font-family;
}


// Block Rahmen
.block-editor-block-list__layout .block-editor-block-list__block.is-selected > .block-editor-block-list__block-edit::before {
  border-color: $primary-color;
  background: $light-gray;
  box-shadow: -3px 0 0 0 $primary-color;
}
// Icons überhalb der Blöcke
.block-editor-block-toolbar {
  box-shadow: -3px 0 0 0 $primary-color;
}
/* =============================================================================
// ACF Styles
// -----------------------------------------------------------------------------
*/
/* ACF Gruppen/Repeater Felder mit der Formatierung "Tabelle" */
.acf-table > thead > tr > th {
  background: $light-gray;
}
/* ACF Felder im Content Block abwechselnde Farbe zur besseren Übersicht*/
.acf-fields > .acf-field{
&.white{
  background: $white;
}
&.gray{
  background: $light-gray;
}
:nth-child(odd) {
  background: $light-gray;
  @include breakpoint(large) {
    background: none;
  }
}
}



/* ACF Felder Größen / Breiten per Klasse über Breakpoints stylen*/

.acf-fields > .acf-field{
    @include breakpoint(small only) {
    width: 100% !important;
  }
    @include breakpoint(medium) {
      width: auto;
    }
    &:first-child{
      @include breakpoint(small only) {
      width: 100% !important;
    }
      @include breakpoint(medium) {
        width: auto;
      }
    }
}




/* Splitmodul Hintergrundfarbe*/
[data-type="acf/cbsplitmodul"] {
  //  background-color: $medium-gray;
}

/*Reiter der ACF Felder */
.acf-field-object.open > .handle {
  background: $primary-color !important;
  border: 1px solid $dark-gray !important;
  text-shadow: $dark-gray 0 1px 0 !important;
}

/* ACF Gruppen */
.inside.acf-fields.-top{
  >.acf-field-group{
  >.acf-input{
    -webkit-box-shadow: 0px 0px 5px 0px rgba($primary-color,0.4);
-moz-box-shadow: 0px 0px 5px 0px rgba($primary-color,0.4);
box-shadow: 0px 0px 5px 0px rgba($primary-color,0.4);
  }
}
}

.acf-field-group{
  .acf-field-group{
    background: $light-gray;
    &:nth-child(odd){
      background: $white;
    }
}
}

/* Group-Field Gutenberg Border */
.wp-block[data-type^="core/group"]{
  border: 1px dashed $primary-color;
}


// Switch Button (Wahr / Falsch) in den ACF Blöcken

.acf-switch:hover, .acf-switch.-focus{
  border-color: $primary-color !important;
  background: $primary-color !important;
  color: $body-font-color !important;
}

.acf-switch.-on{
  border-color: $primary-color !important;
  background: $primary-color !important;
  color: $body-font-color !important;
}

.acf-switch{
  .acf-switch-on{
    color:$white;
    text-shadow: darken($primary-color,10%) 0 1px 0;
  }
}

.acf-switch:hover .acf-switch-slider, .acf-switch.-focus .acf-switch-slider{
  border-color: darken($primary-color,10%);
}

// ACF Radio Buttons
.components-modal__frame input[type="checkbox"]:checked, .components-modal__frame input[type="radio"]:checked, .components-popover input[type="checkbox"]:checked, .components-popover input[type="radio"]:checked, .edit-post-header input[type="checkbox"]:checked, .edit-post-header input[type="radio"]:checked, .edit-post-sidebar input[type="checkbox"]:checked, .edit-post-sidebar input[type="radio"]:checked, .edit-post-text-editor input[type="checkbox"]:checked, .edit-post-text-editor input[type="radio"]:checked, .edit-post-visual-editor input[type="checkbox"]:checked, .edit-post-visual-editor input[type="radio"]:checked, .editor-post-publish-panel input[type="checkbox"]:checked, .editor-post-publish-panel input[type="radio"]:checked{
  background: $primary-color !important;
  border-color: $primary-color !important;
}

/* =============================================================================
// Login Bereich
// -----------------------------------------------------------------------------
*/
// Hintergrundfläche der Login Seite
body.login-action-login,
body.login-action-lostpassword {
  background: $light-gray;
  /* Farben des Corporate Designs */
  background: radial-gradient(circle, darken($light-gray, 15%) 0%, darken($light-gray, 15%) 10%, $light-gray 30%);
  color: $dark-gray !important;
  font-weight: bold;
  font-size: 15px;
}
// login Maske
#loginform {
  border-left: 4px solid $primary-color; //  border-right: 4px solid $primary-color;
  //  border-radius: 8px;
}
// message Box über Login Maske
.login #login_error,
.login .message {
  font-family: $body-font-family; //  border-radius: 8px;
  border-left: 4px solid $primary-color !important; //  border-right: 4px solid $primary-color !important;
  background: $light-gray;
}
// schriftart Login Maske
.login label {
  font-family: $body-font-family;
  color: $body-font-color !important;
}
// Anmelden Button
#login {
  .submit {
    .button {
      font-family: $body-font-family;
      background: $primary-color;
    }
  }
  // #backtoblog => Zurück zum Frontend unterhalb der Login maske
  // #nav => Passwort vergessen
  #backtoblog,
  #nav {
    font-family: $body-font-family;
  }
  // Logo überhalb der Maske
  .login h1 a,
  h1 a {
    background-image: url("../img/projekt/logo.svg");
    width: 230px;
    background-size: 230px auto;
    font-family: $body-font-family;
  }
}
// Anmelden Button
.wp-core-ui .button-primary {
  background: $primary-color !important;
  border-color: $primary-color !important;
  box-shadow: 0 1px 0 $primary-color !important;
  text-shadow: none !important;
}
